import {Component, OnInit, ViewChild} from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {Router} from '@angular/router';
import {RecommendationService} from '../../services/recommendation.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  currentUserId: string | undefined;
  isTechnician: boolean;

  private resetRuleEngineMessage: string;
  private ruleEngineSuccess = true;
  private ruleEngineResetting = false;

  constructor(private readonly authService: AuthService,
              private readonly router: Router,
              private readonly recommendationService: RecommendationService,
              private readonly modalService: NgbModal) {
    this.authService.auth$.subscribe(authState => {
      this.currentUserId = authState.userId;
      this.isTechnician = authState.isTechnician;
    });
  }

  @ViewChild('resetModal', {static: false})
  resetModal;

  ngOnInit() {
  }

  async signOutClick() {
    this.currentUserId = undefined;
    this.isTechnician = false;
    await this.authService.signOut();
    await this.router.navigate(['/login'], {state: {data: "Successfully Signed Out"}});
  }

  async resetRulesEngine() {
    // Disallow clicking while reset is running
    if (this.ruleEngineResetting) {
      return;
    }
    this.ruleEngineResetting = true;
    this.ruleEngineSuccess = true;
    let message = "Rule Engine successfully reset!";
    try {
      await this.recommendationService.resetRulesEngine();
    } catch (e) {
      this.ruleEngineSuccess = false;
      message = "Rule Engine failed to reset: " + e.message;
    }
    this.resetRuleEngineMessage = message;
    this.ruleEngineResetting = false;
    await this.modalService.open(this.resetModal, {ariaLabelledBy: 'resetModal'}).result;
  }
}
