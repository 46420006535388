import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-error-success-text',
  templateUrl: './error-or-success-text.component.html',
  styleUrls: ['./error-or-success-text.component.scss']
})
export class ErrorOrSuccessTextComponent implements OnInit {

  class = "alert alert-danger";

  @Input() text: string;

  @Input('isError') set isError(isError: boolean) {
    if (isError) {
      this.class = "alert alert-danger";
    } else {
      this.class = "alert alert-success";
    }
  }

  constructor() { }

  ngOnInit() {
  }

}
