import {Component, OnInit, ViewChild} from '@angular/core';
import { Location } from '@angular/common';
import {Router} from '@angular/router';
import {Screenshot} from '../dashboard/screenshot-list/screenshot-list.component';
import {NgForm} from '@angular/forms';
import {Recommendation} from '../dashboard/recommendation-sidebar/recommendation-sidebar.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Ticket} from '../../models/ticket';
import {RecommendationService} from '../../services/recommendation.service';
import {TicketService} from '../../services/ticket.service';
import {fileToBase64, fileToDataURI, getFileExtension, toZip} from '../../utils/file-utils';
import {RecommendationEmailTemplateComponent} from '../recommendation-email-template/recommendation-email-template.component';
import {FaultService} from '../../services/fault.service';

@Component({
  selector: 'app-recommendation-page',
  templateUrl: './recommendation-page.component.html',
  styleUrls: ['./recommendation-page.component.scss']
})
export class RecommendationPageComponent implements OnInit {

  screenshots: Array<Screenshot>;
  screenshotNames = "";

  recommendations: Array<Recommendation>;

  additionalRecommendationOptions = ["None"];

  statusOptions = ["In progress", "Resolved"];

  ticket: Ticket;

  errorText: string;
  additionalRecommendation = "None";

  additionalFiles: Array<File> = [];

  @ViewChild(RecommendationEmailTemplateComponent, {static: false})
  emailTemplate: RecommendationEmailTemplateComponent;

  constructor(private readonly router: Router,
              private readonly location: Location,
              private readonly modalService: NgbModal,
              private readonly ticketService: TicketService,
              private readonly recommendationService: RecommendationService,
              private readonly faultService: FaultService) {

    const navigation = this.router.getCurrentNavigation();
    if (navigation.extras && navigation.extras.state) {
      const state = navigation.extras.state as {data: {ticket: Ticket, recommendations: Array<Recommendation>, screenshots: Screenshot[]}};
      this.screenshots = state.data.screenshots;
      this.screenshotNames = this.screenshots.map(s => s.name).join(", ");
      this.recommendations = state.data.recommendations;
      this.ticket = state.data.ticket;
      this.recommendationService.getRecommendations(this.ticket.primaryFaultCode)
        .then(recs => {
          this.additionalRecommendationOptions = this.additionalRecommendationOptions.concat(recs);
        });
    } else {
      this.location.back();
    }
  }

  ngOnInit() {
  }

  async onSubmit(myForm: NgForm, generateOutput: boolean) {
    if (this.screenshots.length) {
      const zip = await toZip(this.screenshots.map(s => ({name: s.name + ".png", dataURI: s.url})));
      this.ticket.traceviewGraphZipFile = {
        traceviewGraphZipFileName: this.ticket.primaryFaultFile + "_graphs",
        traceviewGraphZipFileType: "zip",
        traceviewGraphZipFileBase64: zip
      };
    }

    if (this.additionalRecommendation !== "None") {
      this.ticket.additionalRecommendation = [this.additionalRecommendation];
    }

    if (this.additionalFiles.length) {
      let additionalFilesBase64;
      let fileType;
      let fileName;
      if (this.additionalFiles.length > 1) {
        fileType = 'zip';
        fileName = this.ticket.primaryFaultFile.fileName + "_troubleshooting.zip";
        const fileData = [];
        for (const file of this.additionalFiles) {
          fileData.push({name: file.name, dataURI: await fileToDataURI(file)});
        }
        additionalFilesBase64 = await toZip(fileData);
      } else {
        fileType = getFileExtension(this.additionalFiles[0].name);
        fileName = this.additionalFiles[0].name;
        additionalFilesBase64 = await fileToBase64(this.additionalFiles[0]);
      }
      this.ticket.troubleshootingFile = {
        troubleshootingFileName: fileName,
        troubleshootingFileType: fileType,
        troubleshootingFileBase64: additionalFilesBase64
      };
    }

    this.ticket.suggestedRecommendation = this.recommendations.filter(r => r.checked).map(r => r.text);

    await this.ticketService.updateTicket(this.ticket).then(() => {}).catch(err => console.error(err));
    if (generateOutput) {
      const html = this.emailTemplate.getHtml();
      await this.ticketService.sendTicketEmail(this.ticket, html).then(() => {}).catch(err => console.error(err));
    }
    await this.router.navigate(['tickets']);
  }

  showGraphsModal(content) {
    this.modalService.open(content, {ariaLabelledBy: 'screenshotsmodal', size: 'xl'}).result.then(r => {});
  }

  handleAdditionalFiles(event: Event) {
    this.additionalFiles = event.target['files'];
  }

  closePage() {
    // this.router.navigate(['/dashboard/' + this.ticket.caseId]);
    this.router.navigate(['/tickets']);
  }

  private async downloadArchive() {
    const archive = await this.faultService.getOriginalTracebackFile(this.ticket.uploadedTracebackPath);

    const extension = getFileExtension(archive.filename);

    const a = document.createElement("a");
    a.href = `data:application/${extension};base64,` + archive.base64;
    a.download = archive.filename;
    a.click();
  }
}
