import {Component, OnInit} from '@angular/core';
import {TicketService} from '../../../services/ticket.service';
import {Ticket} from '../../../models/ticket';
import 'datatables.net';

import * as dateFormat from 'dateformat';
import {Router} from '@angular/router';

@Component({
  selector: 'app-list-faults',
  templateUrl: './list-faults.component.html',
  styleUrls: ['./list-faults.component.scss']
})
export class ListFaultsComponent implements OnInit {

  searchParams: Partial<Ticket> = {};

  fromDate: Date;
  toDate: Date;

  tableData: Array<Ticket> = [];

  table: DataTables.Api;

  comparingTicket: Ticket;
  comparingFile: string;

  tableErrorText: string;

  constructor(private router: Router, private readonly ticketService: TicketService) {
    const navigation = this.router.getCurrentNavigation();
    if (navigation.extras && navigation.extras.state) {
      const state = navigation.extras.state as {data: {parentTicket: Ticket, firstFile: string}};
      this.comparingTicket = state.data.parentTicket;
      this.comparingFile = state.data.firstFile;
    }
  }

  ngOnInit() {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    this.fromDate = dateFormat(yesterday, "yyyy-mm-dd");
    this.toDate = dateFormat(new Date(), "yyyy-mm-dd");
  }

  onSearch() {
    this.tableErrorText = "";
    this.ticketService.getTickets(this.searchParams, this.fromDate, this.toDate).then(tickets => {
      if (this.comparingTicket) {
        tickets = tickets.filter(t => t.caseId !== this.comparingTicket.caseId);
      }
      this.tableData = tickets;
      if (tickets.length) {
        if (this.table) {
          this.table.destroy();
        }
        $(document).ready(() => {
          this.table = $('#dataTable').DataTable({
            paging: true,
            pageLength: 10,
            lengthMenu: [ 10, 25, 50, 75, 100 ],
            lengthChange: true,
            autoWidth: true,
            scrollX: true
          });
          this.table.column('0:visible').order('desc').draw();
        });
      } else {
        this.tableErrorText = "No search results found";
      }
    });
  }

  buttonClicked(ticket: Ticket) {
    if (!this.comparingTicket) {
      this.router.navigate(['/dashboard/' + ticket.caseId]).then();
    } else {
      this.router.navigate(['/dashboard/' + this.comparingTicket.caseId],
        {state: {data: {parentTicket: this.comparingTicket, compareTo: ticket}}}).then();
    }
  }

  closePage() {
    this.router.navigate(['/home']);
  }
}
