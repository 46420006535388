import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {GraphControlService} from '../../../services/graph-control.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

export interface Screenshot {
  name: string;
  url: string;
  notes?: string;
}

@Component({
  selector: 'app-screenshot-list',
  templateUrl: './screenshot-list.component.html',
  styleUrls: ['./screenshot-list.component.scss']
})
export class ScreenshotListComponent implements OnInit {

  screenshots: Array<Screenshot> = [];
  existingNotes: string;

  @Output()
  screenshotsUpdated: EventEmitter<Array<Screenshot>> = new EventEmitter<Array<Screenshot>>();

  constructor(private graphControlService: GraphControlService, private modalService: NgbModal) {
    this.graphControlService.saveGraphScreenshot$.subscribe((event) => {
      let existing = this.screenshots.find(s => s.name === event.name);
      const originalName = event.name;
      let endNumber = 1;
      while (existing) {
        event.name = originalName.trim() + "-" + endNumber;
        existing = this.screenshots.find(s => s.name === event.name);
        endNumber++;
      }
      this.screenshots.push({name: event.name, url: event.url});
      this.screenshotsUpdated.emit(this.screenshots);

    });
  }

  ngOnInit() {
  }

  delete(index: number) {
    this.screenshots.splice(index, 1);
  }

  openModal(content, index: number) {
    this.existingNotes = this.screenshots[index].notes || '';
    this.modalService.open(content, {ariaLabelledBy: 'notesModal'}).result.then(r => {
      this.screenshots[index].notes = r;
      this.screenshotsUpdated.emit(this.screenshots);
    })
      .catch(() => {});
  }

}
