import {Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-show-points-button',
  templateUrl: './show-points-button.component.html',
  styleUrls: ['./show-points-button.component.scss']
})
export class ShowPointsButtonComponent implements OnInit {

  @Output()
  clicked: EventEmitter<boolean> = new EventEmitter<boolean>();

  showPoints = false;

  constructor() { }

  ngOnInit() {
  }

  private handleClick() {
    this.showPoints = !this.showPoints;
    this.clicked.emit(this.showPoints);
  }
}
