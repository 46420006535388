import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Ticket} from '../models/ticket';
import {environment} from '../../environments/environment';
import {AuthService} from './auth.service';

interface Attachment {
  filename: string;
  filetype: string;
  base64: string;
}

export interface CreateTicketResponse {
  status: string;
  message: string;
  ticket_number: number;
  case_id: string;
}

@Injectable({
  providedIn: 'root'
})

export class TicketService {

  private baseUrl = environment.faultApiUrl;
  private emailUrl = environment.emailUrl;

  constructor(private http: HttpClient, private authService: AuthService) {
  }

  public async createTicket(ticket: Partial<Ticket>): Promise<CreateTicketResponse> {
    return this.http.post(this.baseUrl + 'save', ticket).pipe().toPromise().then(r => r as CreateTicketResponse);
  }

  public async getTicket(ticketId: string): Promise<Ticket> {
    return this.http.get(this.baseUrl + 'retrieve/' + ticketId).pipe().toPromise().then(r => r['data'] as Ticket);
  }

  public async getTracebackFile(ticketId: string): Promise<Ticket> {
    return this.http.get(this.baseUrl + 'get-traceback-file/' + ticketId).pipe().toPromise().then(r => r['data'] as Ticket);
  }

  public async updateTicket(ticket: Partial<Ticket>): Promise<any> {
    const body = {
      endUsername: ticket.endUsername,
      city: ticket.city,
      state: ticket.state,
      zipCode: ticket.zipCode,
      country: ticket.country,
      caseDescription: ticket.caseDescription,
      siteLocation: ticket.siteLocation,
      siteAddress: ticket.siteAddress,
      applicationName: ticket.applicationName,
      systemName: ticket.systemName,
      suggestedRecommendation: ticket.suggestedRecommendation,
      additionalRecommendation: ticket.additionalRecommendation || [],
      additionalComments: ticket.additionalComments || "",
      finalComments: ticket.finalComments || "",
      status: ticket.status,
      troubleshootingFile: ticket.troubleshootingFile,
      traceviewGraphZipFile: ticket.traceviewGraphZipFile,
      userDriveSerialNumber: ticket.userDriveSerialNumber
    };
    return this.http.post(this.baseUrl + 'update/' + ticket.caseId, body).pipe().toPromise();
  }

  public async getTickets(searchParams?: Partial<Ticket>, fromDate?: Date, toDate?: Date): Promise<Array<Ticket>> {
    const body = {
      caseId: searchParams.caseId || '',
      ticketNumber: searchParams.ticketNumber || '',
      customerEmail: searchParams.customerEmailAddress || '',
      serialNumber: searchParams.userDriveSerialNumber || '',
      companyName: searchParams.companyName || '',
      endUsername: searchParams.endUsername || '',
      primaryFaultCode: searchParams.primaryFaultCode || ''
    };
    if (fromDate) {
      body['fromDate'] = fromDate;
    }
    if (toDate) {
      body['toDate'] = toDate;
    }
    return this.http.post<Array<Ticket>>(this.baseUrl + 'filter', body).pipe().toPromise().then(r => r['data'] as Array<Ticket>);
  }

  public async sendTicketEmail(ticket: Ticket, html: string): Promise<any> {

    const technicianEmail = (await this.authService.getCurrentUser()).email;
    const subject = `Recommendations - Ticket #${ticket.ticketNumber}`;

    let attachment: Attachment | undefined;
    if (ticket.troubleshootingFile) {
      attachment = {
        filename: ticket.troubleshootingFile.troubleshootingFileName,
        filetype: ticket.troubleshootingFile.troubleshootingFileType,
        base64: ticket.troubleshootingFile.troubleshootingFileBase64
      };
    }

    const body = {'to': technicianEmail, 'subject': subject, 'html': html};
    if (attachment) {
      body['attachment'] = attachment;
    }

    return this.http.post(this.emailUrl, body).pipe().toPromise();
  }

}
