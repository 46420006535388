import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-page-selector',
  templateUrl: './page-selector.component.html',
  styleUrls: ['./page-selector.component.scss']
})
export class PageSelectorComponent implements OnInit {

  faAngleLeft = faAngleLeft;
  faAngleRight = faAngleRight;

  @Input()
  maxPageNumber = 5;

  pageNumber = 1;

  @Input('pageNumber')
  set overridePageNumber(n: number) {this.pageNumber = n}

  @Output()
  setPageNumber: EventEmitter<number> = new EventEmitter<number>();

  constructor() { }

  ngOnInit() {
  }

  decrement() {
    if (this.pageNumber > 1) {
      this.pageNumber--;
      this.setPageNumber.emit(this.pageNumber);
    }
  }

  increment() {
    if (this.pageNumber < this.maxPageNumber) {
      this.pageNumber++;
      this.setPageNumber.emit(this.pageNumber);
    }
  }

  typedPageNumber(event: number) {
    if (event >= 1 && event <= this.maxPageNumber) {
      this.pageNumber = event;
      this.setPageNumber.emit(this.pageNumber);
    }
  }
}
