import {Component, OnInit} from '@angular/core';
import {GraphControlService} from '../../../services/graph-control.service';
import {AnnotationService} from '../../../services/annotation/annotation.service';
import {ScaleService} from '../../../services/scale.service';

@Component({
  selector: 'app-graph-footer',
  templateUrl: './graph-footer.component.html',
  styleUrls: ['./graph-footer.component.scss']
})
export class GraphFooterComponent implements OnInit {

  xMin: string;
  xMax: string;

  unit = "ms";

  multiplierToUnit = {
    1: "s",
    1000: "ms",
    1000000: "µs"
  }

  redAnnotationX: string;
  blueAnnotationX: string;

  annotationEnabled = true;

  constructor(private readonly graphControlService: GraphControlService,
              private readonly annotationService: AnnotationService,
              private readonly scaleService: ScaleService) {
    this.scaleService.scaledValues$.subscribe((values: Array<number>) => {
      this.xMin = values[0].toFixed(2);
      this.xMax = values[1].toFixed(2);
    });

    this.scaleService.multiplier$.subscribe(multiplier => {
      this.unit = this.multiplierToUnit[multiplier];
    });

    this.annotationService.scaledPositions$.subscribe((values) => {
      if (values[0] && values[1]) {
        this.redAnnotationX = (values[0] * 1).toFixed(2);
        this.blueAnnotationX = (values[1] * 1).toFixed(2);
      }
    });
  }

  ngOnInit() {
  }

  announceChange() {
    this.scaleService.setScaledValues([parseFloat(this.xMin), parseFloat(this.xMax)]);
  }

  annotationEnableClicked() {
    this.annotationEnabled = !this.annotationEnabled;
    this.annotationService.setShowAnnotations(this.annotationEnabled);
  }
}
