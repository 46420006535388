import * as JSZip from 'jszip';

export function fileToBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = error => reject(error);
  });
}

export function fileToDataURI(file: File): Promise<string> {
  return new Promise<string>(((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = error => reject(error);
  }));
}

export function getFileExtension(name: string) {
  const split = name.split(".");
  return split[split.length - 1];
}

export async function toZip(files: Array<{name: string, dataURI: string}>): Promise<string> {
  const zip = new JSZip();
  for (const file of files) {
    zip.file(file.name, file.dataURI.substring(file.dataURI.indexOf('base64,') + 'base64,'.length), {base64: true});
  }
  const output = await zip.generateAsync({type: "base64"});
  return output;
}
