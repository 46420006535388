import {Component, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {User} from '../../../models/user';
import {AuthService} from '../../../services/auth.service';
import {UserService} from '../../../services/user.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  user: User = {} as User;

  previousEmail: string;

  errorText: string;
  phoneError: string;
  password: string;
  passwordConf: string;

  phonePattern = new RegExp(/[+][0-9]{11,}/);

  currentUserId: string;
  currentUserIsTechnician = false;
  editingUser = false;
  userIdToEdit: string;
  originalUserType: string;

  userEnabled: boolean | undefined = undefined;

  @ViewChild('confirmModal', {static: false})
  confirmModal;

  constructor(private route: ActivatedRoute,
              private modalService: NgbModal,
              private router: Router,
              private authService: AuthService,
              private userService: UserService) {
    if (this.router.url !== '/register') {
      this.editingUser = true;
      this.userIdToEdit = this.route.snapshot.paramMap.get('id');
      try {
        this.authService.getCurrentUser().then((user) => {
          this.currentUserIsTechnician = user.type === 'technician';
          this.currentUserId = user.id;
          this.previousEmail = user.email;
          if (user.type !== 'technician' && this.userIdToEdit !== user.id) {
            this.router.navigate(['/']).then();
          } else if (this.userIdToEdit !== user.id) {
            this.userService.getUser(this.userIdToEdit).then(u => {
              this.user = u;
              this.originalUserType = u.type;
              this.userEnabled = u.enabled;
            });
          } else {
            this.user = user;
            this.originalUserType = user.type;
          }
        });
      } catch (error) {
        console.error(error);
      }
    }
  }

  ngOnInit() {
  }

  async onSubmit(myForm: NgForm) {
    if (myForm.valid) {
      this.errorText = undefined;
      if (!this.editingUser && this.password !== this.passwordConf) {
        this.errorText = 'Password Confirmation does not match.';
      } else {
        let responseMessage = 'Please check email to confirm account';
        try {
          if (this.editingUser) {
            if (this.currentUserIsTechnician && this.originalUserType !== this.user.type) {
              try {
                await this.modalService.open(this.confirmModal, {ariaLabelledBy: 'confirmModal'}).result;
              } catch (e) {
                return;
              }
            }
            await this.userService.updateUser(this.user);
            responseMessage = "Profile successfully updated!";
            if (this.previousEmail !== this.user.email && this.currentUserId === this.userIdToEdit) {
              responseMessage += " Please check your email to verify new email address.";
            }
          } else {
            const user = await this.authService.signUp(this.user, this.password);
            await this.router.navigate(['/login'], {state: {data: responseMessage}});
            return;
          }
          await this.router.navigate(['/users'], {state: {data: responseMessage}});
        } catch (error) {
          this.errorText = error.message;
        }
      }
    }
  }

  phoneChanged() {
    this.phoneError = undefined;
    if (!this.user.phoneNumber.startsWith('+')) {
      this.user.phoneNumber = "+1" + this.user.phoneNumber;
    }
    if (!this.phonePattern.exec(this.user.phoneNumber)) {
      this.phoneError = 'Phone number must be in format +12105550123';
    }
  }

  closePage() {
    if (this.currentUserIsTechnician) {
      this.router.navigate(['/users']);
    } else {
      this.router.navigate(['/home']);
    }
  }
}
