import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';

/**
 * Class to control x min and max for graphs on screen. Handles the conversion between absolute
 * units (seconds) and scaled units for the given data
 */
@Injectable({
  providedIn: 'root'
})
export class ScaleService {

  private values: Array<number>;
  private multiplierValue = 1000;

  private rawValuesSource = new BehaviorSubject<Array<number | undefined>>([0, 0]);
  private scaledValuesSource = new BehaviorSubject<Array<number | undefined>>([0, 0]);
  private multiplierSource = new BehaviorSubject<number>(1);

  rawValues$ = this.rawValuesSource.asObservable();
  scaledValues$ = this.scaledValuesSource.asObservable();
  multiplier$ = this.multiplierSource.asObservable();

  constructor() { }

  /**
   * Set the x min and max using absolute second values
   * @param values The values in seconds on the x-axis
   */
  setRawValues(values: Array<number>): void {
    this.values = values;
    this.emitValues();
  }

  /**
   * Set the values using scaled units (e.g. milliseconds)
   * Will convert to absolute values using the multiplier
   * @param values The multiplied positions
   */
  setScaledValues(values: Array<number>): void {
    this.values = values.map(p => p / this.multiplierValue);
    this.emitValues();

  }

  setMultiplier(value: number) {
    this.multiplierValue = value;
    this.multiplierSource.next(value);
  }

  getMultiplier(): number {
    return this.multiplierValue;
  }

  private emitValues() {
    this.rawValuesSource.next(this.values);
    this.scaledValuesSource.next(this.values.map(p => p * this.multiplierValue));
  }

}
