import {Component, OnInit, ViewChild} from '@angular/core';
import {GraphControlService} from '../../../services/graph-control.service';
import {ActivatedRoute, Router} from '@angular/router';
import {TicketService} from '../../../services/ticket.service';
import {FaultService} from '../../../services/fault.service';
import {RecommendationService} from '../../../services/recommendation.service';
import {Recommendation} from '../recommendation-sidebar/recommendation-sidebar.component';
import {Screenshot} from '../screenshot-list/screenshot-list.component';
import {FaultFile, Ticket} from '../../../models/ticket';
import {FaultData} from '../../../models/graph-data';
import {GraphPageComponent} from '../graph-page/graph-page.component';
import { faChevronRight, faChevronLeft, faAward } from '@fortawesome/free-solid-svg-icons';
import {removeLFromSignal} from '../../../utils/string-utils';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {DriveEventsTableData} from '../../../models/drive-events';
import {FaultCodes} from '../fault-codes-list/fault-codes-list.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers: [GraphControlService]
})
export class DashboardComponent implements OnInit {

  faChevronRight = faChevronRight;
  faChevronLeft = faChevronLeft;
  faAward = faAward;

  mode: "recommendations" | "settings" = 'recommendations';

  @ViewChild(GraphPageComponent, {static: false})
  pageComponent: GraphPageComponent;

  @ViewChild('driveeventsmodal', {static: false})
  driveEventsModal;

  faultFiles: Array<FaultFile>;

  private signalData: FaultData;
  private signalData2: FaultData;
  private comparingData: boolean;
  private comparingSeparateFiles = false;

  private defaultSignals = [];

  private sameGraph = false;
  private multiColumn = false;
  private showPoints = false;
  private showGrid = false;
  private showTime = false;

  private showSidebar = true;
  private sidebarStyle = {width: 'min(70%, calc(95% - 550px))'};

  private activeFault: string;
  private activeFault2: string;
  private recommendationFault: string;

  private fileChanged = false;

  private recommendations = [];
  private screenshots = [];
  private ticket: Ticket;
  private comparingTicket: Ticket;
  private suggestedRecommendations: Array<string>;

  private faultCodes: FaultCodes;

  private driveEventsType = 'Dataset';

  private driveEventsTableData: DriveEventsTableData;

  constructor(private route: ActivatedRoute,
              private graphControlService: GraphControlService,
              private ticketService: TicketService,
              private faultService: FaultService,
              private recommendationService: RecommendationService,
              private router: Router,
              private modalService: NgbModal) {
    const ticketId = this.route.snapshot.paramMap.get('ticket-id');
    const navigation = this.router.getCurrentNavigation();
    if (navigation.extras && navigation.extras.state) {
      const state = navigation.extras.state as {data: {parentTicket: Ticket, compareTo: Ticket}};
      this.compareTickets(state.data.parentTicket, state.data.compareTo).then();
    } else {
      this.loadData(ticketId).then();
    }
    this.graphControlService.runRecommendations$.subscribe(r => this.runRecommendations().then());
    this.graphControlService.compareLocalFiles$.subscribe(files => this.compareLocalFiles(files).then());
    this.graphControlService.compareExternalFiles$.subscribe(event => this.compareExternalFiles(event).then());
  }

  ngOnInit(): void {
  }

  setTab(tab: "recommendations" | "settings") {
    this.mode = tab;
  }

  handleSameGraph($event: boolean) {
    this.sameGraph = $event;
  }

  handleMultiColumn($event: boolean) {
    this.multiColumn = $event;
  }

  private async loadData(ticketId: string) {
    this.ticket = await this.ticketService.getTicket(ticketId);
    this.driveEventsTableData = await this.faultService.getExtraDetails(this.ticket.caseId);
    this.recommendations = this.ticket.ruleEngineRecommendation || [];
    this.suggestedRecommendations = this.ticket.suggestedRecommendation || [];
    this.faultFiles = this.ticket.tracebackJsonFiles;
    await this.fileNameSelected(this.faultFiles[0].fileName);
    this.recommendationFault = this.activeFault;
    this.comparingData = false;
  }

  async fileNameSelected(filename: string) {
    if (filename !== this.activeFault) {
      this.fileChanged = true;
      this.activeFault = filename;
      this.signalData = await this.faultService
        .getFaultSignals(this.ticket.tracebackJsonFiles.find(f => f.fileName === this.activeFault).fullPath);
      await this.setDefaultSignals();
      this.faultCodes = {
        primaryFaultCode: this.signalData.faultCode || '',
        secondaryFaultCodes: this.signalData.secondaryFaultCodes || []
      };
      this.comparingData = false;
      this.activeFault2 = undefined;
      this.graphControlService.announceSignalsLoaded(this.signalData.signals);
    }
  }

  private async runRecommendations() {
    const fault = this.faultFiles.find(f => f.fileName === this.activeFault);
    this.recommendationFault = fault.fileName;
    const faultSignals = await this.faultService.getFaultSignals(fault.fullPath);
    this.recommendations = await this.recommendationService.getRecommendations(faultSignals.faultCode, faultSignals.secondaryFaultCodes);
  }

  finalizeRecommendations(recommendations: Array<Recommendation>) {
    this.router.navigate(['/recommendation'],
      {state: {data: {ticket: this.ticket, recommendations: recommendations, screenshots: this.screenshots}}}).then();
  }

  screenshotsUpdated(screenshots: Array<Screenshot>) {
    this.screenshots = screenshots;
  }

  private async compareLocalFiles(files: { first: string; second: string }) {
    const fault1 = this.ticket.tracebackJsonFiles.find(f => f.fileName === files.first) ||
      this.ticket.extractedTraceviewFiles.find(f => f.fileName === files.first);
    const fault2 = this.ticket.tracebackJsonFiles.find(f => f.fileName === files.second) ||
      this.ticket.extractedTraceviewFiles.find(f => f.fileName === files.second);

    this.activeFault = fault1.fileName;
    this.activeFault2 = fault2.fileName;

    this.signalData2 = await this.faultService.getFaultSignals(fault2.fullPath);
    this.signalData = await this.faultService.getFaultSignals(fault1.fullPath);
    this.graphControlService.announceSignalsLoaded(this.signalData.signals);
    await this.setDefaultSignals();
    this.comparingData = true;
  }

  private async setDefaultSignals() {
    // Remove underscore from end of fault code if present
    const trimmedPrimaryFaultCode = this.ticket.primaryFaultCode[this.ticket.primaryFaultCode.length - 1] === "_" ?
      this.ticket.primaryFaultCode.substring(0, this.ticket.primaryFaultCode.length - 1) : this.ticket.primaryFaultCode;
    const defaultSignals = await this.faultService.getDefaultSignals(trimmedPrimaryFaultCode);
    const filteredDefaultSignals = [];
    const availableSignalNames = this.signalData.signals.map(s => s.signalCode);
    defaultSignals.forEach(signal => {
      if (!availableSignalNames.includes(signal)) {
        signal = removeLFromSignal(signal);
        if (availableSignalNames.includes(signal)) {
          filteredDefaultSignals.push(signal);
        }
      } else {
        filteredDefaultSignals.push(signal);
      }
    });
    // Sort so default signals come first
    if (this.signalData && this.signalData.signals) {
      // this.signalData.signals = this.signalData.signals.sort((a, b) => {
      //   if (filteredDefaultSignals.includes(a.signalCode) && filteredDefaultSignals.includes(b.signalCode)) {
      //     return b.samplingRate - a.samplingRate;
      //   } else if (filteredDefaultSignals.includes(a.signalCode)) {
      //     return -1;
      //   } else if (filteredDefaultSignals.includes(b.signalCode)) {
      //     return 1;
      //   } else {
      //     return b.samplingRate - a.samplingRate;
      //   }
      // });
      this.signalData.signals = this.signalData.signals.sort((a, b) => {
        if (filteredDefaultSignals.includes(a.signalCode) && filteredDefaultSignals.includes(b.signalCode)) {
          return b.samplingRate - a.samplingRate;
        }
        return 0;
      });
    }
    this.defaultSignals = filteredDefaultSignals;
  }

  private async compareTickets(parentTicket: Ticket, compareTo: Ticket) {
    this.ticket = parentTicket;
    this.comparingTicket = await this.ticketService.getTicket(compareTo.caseId);
    this.faultFiles = this.ticket.tracebackJsonFiles;
    await this.fileNameSelected(this.faultFiles[0].fileName);
    this.recommendationFault = this.activeFault;
    this.comparingSeparateFiles = true;
  }

  private async compareExternalFiles(event: { parentTicket: Ticket;
      firstFilename: string; comparingTicket: Ticket; secondFilename: string }) {
    const fault1 = this.ticket.tracebackJsonFiles.find(f => f.fileName === event.firstFilename) ||
      this.ticket.extractedTraceviewFiles.find(f => f.fileName === event.firstFilename);
    const fault2 = event.comparingTicket.tracebackJsonFiles.find(f => f.fileName === event.secondFilename) ||
      event.comparingTicket.extractedTraceviewFiles.find(f => f.fileName === event.secondFilename);

    this.activeFault = fault1.fileName;
    this.activeFault2 = fault2.fileName;

    this.comparingData = true;
    this.signalData2 = await this.faultService.getFaultSignals(fault2.fullPath);
    this.signalData = await this.faultService.getFaultSignals(fault1.fullPath);
    this.graphControlService.announceSignalsLoaded(this.signalData.signals);
    await this.setDefaultSignals();
  }

  private handleShowPoints(event: boolean) {
    this.showPoints = event;
  }

  private handleShowGrid(event: boolean) {
    this.showGrid = event;
  }

  private handleShowTime(event: boolean) {
    this.showTime = event;
  }

  private handleSetZoom() {
    this.pageComponent.setZoom();
  }

  handleResetZoom() {
    this.pageComponent.resetZoom();
  }

  private setShowSidebar(show: boolean, mode?: 'recommendations' | 'settings') {
    this.showSidebar = show;
    this.sidebarStyle.width = (show ? 'min(70%, calc(95% - 550px))' : '90%') as string;
    if (mode) {
      this.mode = mode;
    }
  }

  showDriveEventsModal() {
    this.modalService.open(this.driveEventsModal, {ariaLabelledBy: 'screenshotsmodal', size: 'xl'}).result.then(r => {});
  }

  private setDriveEventsType(type: string) {
    this.driveEventsType = type;
  }
}
