import {Component, OnDestroy, OnInit} from '@angular/core';
import {GraphControlService} from '../../../services/graph-control.service';

export interface ScaleConfig {
  graphNumber: number;
  yMin: number;
  yMax: number;
  autoScale: boolean;
}

@Component({
  selector: 'app-scale-selector',
  templateUrl: './scale-selector.component.html',
  styleUrls: ['./scale-selector.component.scss']
})
export class ScaleSelectorComponent implements OnInit, OnDestroy {

  scaleConfigs: Array<ScaleConfig>;
  subscription;

  constructor(private graphControlService: GraphControlService) {
    this.subscription = this.graphControlService.yMinMaxUpdated$.subscribe(event => {
      if (!event.fromCheckbox) {
        this.scaleConfigs = event.configs;
        this.scaleConfigs.forEach(s => {
          s.yMin = parseFloat(s.yMin.toFixed(3));
          s.yMax = parseFloat(s.yMax.toFixed(3));
          if (s.yMin === s.yMax) {
            s.yMin = s.yMin - 10;
            s.yMax = s.yMax + 10;
          }
        });
      }
    });
  }

  ngOnInit() {
  }

  scaleChanged(entry?: ScaleConfig) {
    if (entry) {
      entry.autoScale = false;
    }
    this.graphControlService.announceYMinMaxUpdated(this.scaleConfigs, true);
  }

  checked(entry?: ScaleConfig) {
    this.graphControlService.announceYMinMaxUpdated(this.scaleConfigs, true);
  }

  autoScaleAll(event: Event) {
    const checked: boolean = event.target['checked'];
    this.scaleConfigs.forEach(scale => scale.autoScale = checked);
    this.scaleChanged();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
