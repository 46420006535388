const env = 'uat2';
const baseUrl = "https://j6mt18r57e.execute-api.us-east-1.amazonaws.com/" + env;
const amplifyConfig = require('../assets/amplify.' + env + '.json');

export const environment = {
  production: false,
  userApiUrl: baseUrl + "/user",
  faultApiUrl: baseUrl + "/fault-ticket/",
  ruleEngineUrl: baseUrl + "/rule-engine/generate",
  configurationUrl: baseUrl + "/configuration",
  dashboardUrl: baseUrl + "/dashboard/",
  emailUrl: baseUrl + "/emails/send-email",
  amplify: amplifyConfig
};
