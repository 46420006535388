import {Injectable} from '@angular/core';
import {User, UserType} from '../models/user';
import {CognitoUser, ISignUpResult} from 'amazon-cognito-identity-js';
import Auth from '@aws-amplify/auth';
import {BehaviorSubject} from 'rxjs';
import {map} from 'rxjs/operators';
import {Hub} from '@aws-amplify/core';

export interface AuthState {
  isLoggedIn: boolean;
  userId: string | undefined;
  email: string | undefined;
  isTechnician: boolean;
}

const initialAuthState = {
  isLoggedIn: false,
  userId: null,
  email: null,
  isTechnician: false
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private readonly authState = new BehaviorSubject<AuthState>({
    isLoggedIn: false,
    userId: undefined,
    email: undefined,
    isTechnician: false
  });

  readonly auth$ = this.authState.asObservable();

  readonly isLoggedIn$ = this.auth$.pipe(map(state => state.isLoggedIn));

  constructor() {
    Auth.currentAuthenticatedUser().then(
      (user: any) => this.setUser(user),
      err => this.authState.next(initialAuthState)
    );

    Hub.listen('auth', ({payload: {event, data, message}}) => {
      if (event === 'signIn') {
        this.setUser(data);
      } else {
        this.authState.next(initialAuthState);
      }
    });
  }

  public async signUp(user: User, password: string): Promise<ISignUpResult> {
    const params = {
      username: user.email,
      password: password,
      attributes: {
        given_name: user.firstName,
        family_name: user.lastName,
        phone_number: user.phoneNumber,
        'custom:company_name': user.company
      }
    };
    return Auth.signUp(params);
  }

  public async signIn(email: string, password: string): Promise<User | undefined> {
    const cognito = await Auth.signIn(email, password);
    this.setUser(cognito);
    return this.getCurrentUser(cognito);
  }

  public async getCurrentUser(cognito?: CognitoUser): Promise<User | undefined> {
    if (!cognito) {
      try {
        cognito = await Auth.currentAuthenticatedUser();
      } catch (e) {
        return Promise.reject(e);
      }
    }
    const id = cognito.getUsername();
    const userType = this.getCognitoUserType(cognito);
    return new Promise((resolve, reject) => {
      try {
        cognito.getUserAttributes((err, data) => {
          resolve({
            id: id,
            type: userType,
            email: data.find(att => att.getName() === 'email').getValue(),
            company: data.find(att => att.getName() === 'custom:company_name').getValue(),
            firstName: data.find(att => att.getName() === 'given_name').getValue(),
            lastName: data.find(att => att.getName() === 'family_name').getValue(),
            phoneNumber: data.find(att => att.getName() === 'phone_number').getValue()
          });
        });
      } catch (error) {
        reject(error);
      }
    });
  }

  public async updateCurrentUser(user: User): Promise<void> {
    const cognitoUser: CognitoUser = await Auth.currentAuthenticatedUser();
    const response = await Auth.updateUserAttributes(cognitoUser, {
      email: user.email,
      given_name: user.firstName,
      family_name: user.lastName
    });
  }

  public async getAuthToken(): Promise<string> {
    const user: CognitoUser = await Auth.currentAuthenticatedUser();
    return user.getSignInUserSession().getIdToken().getJwtToken();
  }

  public async signOut(): Promise<void> {
    try {
      await Auth.signOut();
    } catch (error) {
      console.error(error);
    }
  }

  private getCognitoUserType(user: CognitoUser): UserType {
    return (user.getSignInUserSession().getAccessToken().payload['cognito:groups'] as Array<string>)
      .includes('technician') ? 'technician' : 'customer';
  }

  private setUser(user: any) {
    if (!user) {
      return;
    }

    const {
      attributes: {sub: userId, email}
    } = user;

    this.authState.next({isLoggedIn: true, userId, email, isTechnician: this.getCognitoUserType(user) === 'technician'});
  }

}
