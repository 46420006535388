import {Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-show-time-button',
  templateUrl: './show-time-button.component.html',
  styleUrls: ['./show-time-button.component.scss']
})
export class ShowTimeButtonComponent implements OnInit {

  showTime = false;
  @Output()
  clicked: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit() {
  }

  private handleClick() {
    this.showTime = !this.showTime;
    this.clicked.emit(this.showTime);
  }
}
