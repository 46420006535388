import {Component, ElementRef, Input, OnInit} from '@angular/core';
import {Ticket} from '../../models/ticket';
import {Screenshot} from '../dashboard/screenshot-list/screenshot-list.component';

@Component({
  selector: 'app-recommendation-email-template',
  templateUrl: './recommendation-email-template.component.html',
  styleUrls: ['./recommendation-email-template.component.scss']
})
export class RecommendationEmailTemplateComponent implements OnInit {

  @Input()
  ticket: Ticket;

  @Input()
  screenshots: Array<Screenshot>;

  constructor(private ref: ElementRef) { }

  ngOnInit() {
  }

  getHtml(): string {
    return document.getElementById('email-template').innerHTML;
  }

}
