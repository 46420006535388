import {Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-split-columns-button',
  templateUrl: './split-columns-button.component.html',
  styleUrls: ['./split-columns-button.component.scss']
})
export class SplitColumnsButtonComponent implements OnInit {

  @Output()
  clicked: EventEmitter<boolean> = new EventEmitter<boolean>();

  multiColumn = false;

  constructor() { }

  ngOnInit() {
  }

  handleClick() {
    this.multiColumn = !this.multiColumn;
    this.clicked.emit(this.multiColumn);
  }
}
