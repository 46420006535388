import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {AuthService} from './auth.service';
import {from, Observable} from 'rxjs';
import {switchMap} from 'rxjs/operators';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private auth: AuthService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.auth.getAuthToken())
      .pipe(
        switchMap(token => {
          const modified = req.clone({
            setHeaders: {
              'Content-Type': 'application/json',
              Authorization: token
            }
          });

          return next.handle(modified);
        })
      );
  }
}
