export function scale(num, inMin, inMax, outMin, outMax): number {
  return (num - inMin) * (outMax - outMin) / (inMax - inMin) + outMin;
}

export const mathOperator = {
  '+': (x, y) => x + y,
  '-': (x, y) => x - y,
  'x': (x, y) => x * y,
  '÷': (x, y) => x / y
};

export function roundToDecimalPlaces(value, precision) {
  const multiplier = Math.pow(10, precision);
  return Math.round(value * multiplier) / multiplier;
}
