import { Component, OnInit } from '@angular/core';
import {GraphControlService} from '../../../services/graph-control.service';
import {DataTableEntry} from '../graph-page/graph-page.component';

@Component({
  selector: 'app-signal-data',
  templateUrl: './signal-data.component.html',
  styleUrls: ['./signal-data.component.scss']
})
export class SignalDataComponent implements OnInit {

  dataTableEntries: Array<DataTableEntry>;

  constructor(private graphControlService: GraphControlService) {
    this.graphControlService.signalDataUpdated$.subscribe((event: Array<DataTableEntry>) => {
      this.dataTableEntries = event;
    });
  }

  ngOnInit() {
  }

}
