import { Component, OnInit } from '@angular/core';
import { Auth } from 'aws-amplify';
import {Router} from '@angular/router';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  email: string;
  errorText: string;

  constructor(private router: Router) { }

  ngOnInit() {
  }

  async onSubmit() {
    try {
      await Auth.forgotPassword(this.email);
      await this.router.navigate(['/reset-password'], { state: {data: this.email}});
    } catch (error) {
      this.errorText = error.message;
    }
  }

  async onCancel() {
    await this.router.navigate(['/login']);
  }

}
