import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {ForgotPasswordComponent} from './components/user/forgot-password/forgot-password.component';
import {LoginComponent} from './components/user/login/login.component';
import {AuthGuard} from './services/auth.guard';
import {RegisterComponent} from './components/user/register/register.component';
import {ResetPasswordComponent} from './components/user/reset-password/reset-password.component';
import {ListFaultsComponent} from './components/ticket/list-faults/list-faults.component';
import {FaultTicketComponent} from './components/ticket/fault-ticket/fault-ticket.component';
import {AuthTechnicianGuard} from './services/auth.technician.guard';
import {DashboardComponent} from './components/dashboard/dashboard/dashboard.component';
import {RecommendationPageComponent} from './components/recommendation-page/recommendation-page.component';
import {SearchListProfileComponent} from './components/user/search-list-profile/search-list-profile.component';
import { HomeComponent } from './components/home/home.component';


const routes: Routes = [
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  { path: 'confirm-email', component: ResetPasswordComponent },
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent },
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'users/:id', component: RegisterComponent, canActivate: [AuthGuard] },
  { path: 'users', component: SearchListProfileComponent, canActivate: [AuthTechnicianGuard] },
  { path: 'create-ticket', component: FaultTicketComponent, canActivate: [AuthGuard]},
  { path: 'tickets', component: ListFaultsComponent, canActivate: [AuthTechnicianGuard] },
  { path: 'dashboard/:ticket-id', component: DashboardComponent, canActivate: [AuthTechnicianGuard] },
  { path: 'recommendation', component: RecommendationPageComponent, canActivate: [AuthTechnicianGuard] },
  { path: 'home', component: HomeComponent }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
