import { Component, OnInit } from '@angular/core';
import {User} from '../../../models/user';
import {UserService} from '../../../services/user.service';
import {Router} from '@angular/router';
import 'datatables.net';

@Component({
  selector: 'app-search-list-profile',
  templateUrl: './search-list-profile.component.html',
  styleUrls: ['./search-list-profile.component.scss']
})
export class SearchListProfileComponent implements OnInit {

  searchCriteria: Partial<User> = {};

  tableData: Array<User> = [];

  table: DataTables.Api;

  tableErrorText: string;

  constructor(private readonly router: Router, private readonly userService: UserService) { }

  ngOnInit() {
  }

  async onSearch() {
    this.tableErrorText = "";
    const users = await this.userService.getUsers(this.searchCriteria);
    this.tableData = users;
    if (users.length) {
      if (this.table) {
        this.table.destroy();
      }
      $(document).ready(() => {
        this.table = $("#dataTable").DataTable({
          paging: true,
          pageLength: 10,
          lengthChange: true,
          autoWidth: true,
          scrollX: true
        });
        this.table.column('0:visible').order('desc').draw();
      });
    } else {
      this.tableErrorText = "No search results found";
    }
  }

  buttonClicked(user: User) {
    this.router.navigate(['/users/' + user.id]).then();
  }

  closePage() {
    this.router.navigate(['/home']).then();
  }
}
