import {Component, Input, Output, OnInit, EventEmitter} from '@angular/core';
import {faSyncAlt} from '@fortawesome/free-solid-svg-icons';
import {GraphControlService} from '../../../services/graph-control.service';
import {Router} from '@angular/router';

export interface Recommendation {
  checked: boolean;
  text: string;
}

@Component({
  selector: 'app-recommendation-sidebar',
  templateUrl: './recommendation-sidebar.component.html',
  styleUrls: ['./recommendation-sidebar.component.scss']
})
export class RecommendationSidebarComponent implements OnInit {

  faSyncAlt = faSyncAlt;

  loading = false;

  recommendations: Array<Recommendation>;

  @Input('recommendations')
  set setRecommendations(recommendations: {allRecommendations: Array<string>, suggestedRecommendations: Array<string>}) {
    this.recommendations = recommendations.allRecommendations
      .map(r => ({checked: recommendations.suggestedRecommendations.includes(r), text: r}));
    this.loading = false;
    this.fileChanged = false;
  }

  @Input()
  fileChanged: boolean;

  @Input()
  faultDetails: string;

  @Input()
  ticketNumber: number;

  @Output()
  finalizeRecommendations: EventEmitter<Array<Recommendation>> = new EventEmitter<Array<Recommendation>>();

  @Output()
  showDriveEventsModal: EventEmitter<void> = new EventEmitter<void>();

  constructor(private readonly graphControlService: GraphControlService, private router: Router) { }

  ngOnInit() {
  }

  runRecommendations() {
    this.loading = true;
    this.recommendations = [];
    this.graphControlService.announceRunRecommendations();
  }

  finalizeRecommendationsClicked() {
    this.finalizeRecommendations.emit(this.recommendations);
  }

  closePage() {
    this.router.navigate(['/tickets']);
  }
}
