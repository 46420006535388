import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from '../../../services/auth.service';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  email: string;
  password: string;
  errorText: string;
  alertText: string;

  constructor(private router: Router, private authService: AuthService, private userService: UserService) {
    const navigation = this.router.getCurrentNavigation();
    if (navigation.extras && navigation.extras.state) {
      const state = navigation.extras.state as {data: string};
      this.alertText = state.data;
    }
  }

  ngOnInit() {
  }

  async onSubmit() {
    try {
          const user = await this.authService.signIn(this.email, this.password);
          this.errorText = undefined;
          if (this.userService.getUser(user.id) == null) {
            this.errorText =
              "Please contact a technician for getting access to the system";
            return;
          }
          await this.router.navigate(['/home']);
        } catch (error) {
        this.errorText = "Invalid username/password";
    }
  }

  closePage() {
      this.router.navigate(['/home']).then();
  }
}
