import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';

/**
 * Class to control the position and display values of red/blue annotation bars. Handles the conversion between absolute
 * units (seconds) and scaled units for the given data
 */
@Injectable({
  providedIn: 'root'
})
export class AnnotationService {

  private positions: Array<number>;
  private multiplierValue = 1000;
  private showAnnotations = true;

  private rawPositionsSource = new BehaviorSubject<Array<number | undefined>>([0, 0]);
  private scaledPositionsSource = new BehaviorSubject<Array<number | undefined>>([0, 0]);

  rawPositions$ = this.rawPositionsSource.asObservable();
  scaledPositions$ = this.scaledPositionsSource.asObservable();

  constructor() { }

  /**
   * Set the position of the annotations using absolute second values
   * @param positions The positions in seconds on the x-axis
   */
  setRawPositions(positions: Array<number>): void {
    this.positions = positions;
    this.emitValues();
  }

  /**
   * Set the position of the annotations using scaled units (e.g. milliseconds)
   * Will convert to absolute positions using the multiplier
   * @param positions The multiplied positions
   */
  setScaledPositions(positions: Array<number>): void {
    this.positions = positions.map(p => p / this.multiplierValue);
    this.emitValues();

  }

  setMultiplier(value: number) {
    this.multiplierValue = value;
  }

  getMultiplier(): number {
    return this.multiplierValue;
  }

  setShowAnnotations(show: boolean) {
    this.showAnnotations = show;
    if (show) {
      this.emitValues();
    } else {
      this.emitEmpty();
    }
  }

  private emitValues() {
    this.rawPositionsSource.next(this.positions);
    this.scaledPositionsSource.next(this.positions.map(p => p * this.multiplierValue));
  }

  private emitEmpty() {
    this.rawPositionsSource.next([undefined, undefined]);
    this.scaledPositionsSource.next([undefined, undefined]);
  }

}
