import { Injectable } from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {SignalSelection} from '../components/dashboard/signal-selector/signal-selector.component';
import {DataTableEntry} from '../components/dashboard/graph-page/graph-page.component';
import {ScaleConfig} from '../components/dashboard/scale-selector/scale-selector.component';
import {Signal} from '../models/graph-data';
import {Ticket} from '../models/ticket';
import {PredefinedView} from './fault.service';

export interface NewSignalEvent {
  graphNumber: number;
  oldSignalName: string;
  newSignal: Signal;
}

@Injectable({
  providedIn: 'root'
})
export class GraphControlService {

  private signalSelectedSource =
    new BehaviorSubject<{selections: Array<Partial<SignalSelection>>, resetXMinMax: boolean}>({selections: [], resetXMinMax: false});
  private signalDataUpdatedSource = new Subject<Array<DataTableEntry>>();
  private yMinMaxUpdatedSource = new Subject<{configs: Array<ScaleConfig>, fromCheckbox: boolean}>();
  private saveGraphScreenshotSource = new Subject<{name: string, url: string}>();
  private sameGraphEventSource = new Subject<boolean>();
  private mathSignalCreatedSource = new Subject<NewSignalEvent>();
  private runRecommendationsSource = new Subject<void>();
  private compareLocalFilesSource = new Subject<{first: string, second: string}>();
  private compareExternalFilesSource = new Subject<{parentTicket: Ticket, firstFilename: string,
    comparingTicket: Ticket, secondFilename: string}>();
  private predefinedViewSelectedSource = new Subject<PredefinedView>();
  private signalsLoadedSource = new BehaviorSubject<Array<Signal>>([]);

  signalSelected$ = this.signalSelectedSource.asObservable();
  signalDataUpdated$ = this.signalDataUpdatedSource.asObservable();
  yMinMaxUpdated$ = this.yMinMaxUpdatedSource.asObservable();
  saveGraphScreenshot$ = this.saveGraphScreenshotSource.asObservable();
  sameGraphEvent$ = this.sameGraphEventSource.asObservable();
  mathSignalCreated$ = this.mathSignalCreatedSource.asObservable();
  runRecommendations$ = this.runRecommendationsSource.asObservable();
  compareLocalFiles$ = this.compareLocalFilesSource.asObservable();
  compareExternalFiles$ = this.compareExternalFilesSource.asObservable();
  predefinedViewSelected$ = this.predefinedViewSelectedSource.asObservable();
  signalsLoaded$ = this.signalsLoadedSource.asObservable();

  constructor() { }

  public announceSignalSelected(event: Array<Partial<SignalSelection>>, resetXMinMax = false) {
    this.signalSelectedSource.next({selections: event, resetXMinMax: resetXMinMax});
  }

  public announceSignalDataUpdated(event: Array<DataTableEntry>) {
    this.signalDataUpdatedSource.next(event);
  }

  public announceYMinMaxUpdated(event: Array<ScaleConfig>, fromCheckbox: boolean) {
    this.yMinMaxUpdatedSource.next({configs: event, fromCheckbox: fromCheckbox});
  }

  public announceSaveGraphScreenshot(name: string, url: string) {
    this.saveGraphScreenshotSource.next({name: name, url: url});
  }

  public announceSameGraphEvent(event: boolean) {
    this.sameGraphEventSource.next(event);
  }

  public announceMathSignalCreated(graphNumber: number, oldSignalName: string, newSignal: Signal) {
    this.mathSignalCreatedSource.next({graphNumber: graphNumber, oldSignalName: oldSignalName, newSignal: newSignal});
  }

  public announceRunRecommendations() {
    this.runRecommendationsSource.next();
  }

  public announceCompareLocalFiles(firstFilename: string, secondFilename: string) {
    this.compareLocalFilesSource.next({first: firstFilename, second: secondFilename});
  }

  public announceCompareExternalFiles(parentTicket: Ticket,
                                      firstFilenameSelected: string,
                                      comparingTicket: Ticket,
                                      secondFilenameSelected: string) {
    this.compareExternalFilesSource.next({parentTicket: parentTicket,
      firstFilename: firstFilenameSelected,
      comparingTicket: comparingTicket, secondFilename:
      secondFilenameSelected});
  }

  public announcePredefinedViewSelected(view: PredefinedView) {
    this.predefinedViewSelectedSource.next(view);
  }

  public announceSignalsLoaded(signals: Array<Signal>) {
    this.signalsLoadedSource.next(signals);
  }
}
