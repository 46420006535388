import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {FaultData, Signal} from '../models/graph-data';
import {environment} from '../../environments/environment';
import {DriveEventsTableData} from '../models/drive-events';

export interface PredefinedView {
  viewName: string;
  signals: Array<{channel: number, signals: Array<string | object>}>;
}

export interface ArchiveFile {
  base64: string;
  filename: string;
}

@Injectable({
  providedIn: 'root'
})
export class FaultService {

  private baseUrl = environment.faultApiUrl;
  private dashboardUrl = environment.dashboardUrl;

  constructor(private http: HttpClient) {
  }

  public async getFaultSignals(faultPath: string): Promise<FaultData> {
    const body = {tracebackPath: faultPath};
    const data = await this.http.post(this.baseUrl + 'load-traceback-file',
      body).pipe().toPromise().then(r => r['data'] as FaultData);
    let previousIdx = 0;
    data.sampleParameterList.forEach(param => {
      data.signals.forEach((signal, i) => {
        if (i >= previousIdx && i < param.channelMax) {
          signal.faultIdx = param.faultIdx;
        }
      });
      previousIdx = param.channelMax;
    });
    data.signals.forEach(signal => {
      if (!signal.signalPctValue) {
        signal.signalPctValue = 1;
      } else if (typeof signal.signalPctValue !== 'number') {
        const first = ('' + signal.signalPctValue).split(' ')[0];
        if (!isNaN(Number(first))) {
          signal.signalPctValue = Number(first);
        } else {
          signal.signalPctValue = 1;
        }
      }
      signal.signalData = signal.signalData.map(d => d / signal.signalPctValue * 100);
      signal.signalData = signal.signalData.slice(0, signal.blockMax);
    });
    data.signals.forEach(signal => {
      signal.signalMin = Math.min(...signal.signalData);
      signal.signalMax = Math.max(...signal.signalData);
    });

    for (const signal of data.signals) {
      let unit = 'µs';
      let multiplier = 1;
      if (signal.samplingRate >= 1000) {
        unit = 'ms';
        multiplier = 1 / 1000;
      }
      if (signal.samplingRate >= 100000) {
        unit = 's';
        multiplier = 1 / 100000;
      }
      signal.signalDisplayName = signal.signalCode + " (" + Number((signal.samplingRate * multiplier).toFixed(2)) + unit + ")";
    }
    const duplicateData: Array<Signal> = [];
    for (const signal of data.signals) {
      const existing = duplicateData.find(d => d.signalCode === signal.signalCode);
      if (!existing) {
        duplicateData.push(signal);
      } else {
        const smaller = existing.samplingRate < signal.samplingRate ? existing : signal;
        smaller.signalCode = smaller.signalCode + "(L)";
      }
    }
    return data;
  }

  public async getDefaultViews(): Promise<Array<PredefinedView>> {
    return this.http.get(this.dashboardUrl + "get-predefined-signals").pipe().toPromise().then(r => r['data'] as Array<PredefinedView>);
  }

  public async getDefaultSignals(primaryFaultCode: string): Promise<Array<string>> {
    return this.http.get(this.dashboardUrl + "get-faultcode-signals?fault_code=" + primaryFaultCode)
      .pipe().toPromise().then(r => r['data'][0].signals as Array<string>).catch((e) => []);
  }

  public async getExtraDetails(caseId: string): Promise<DriveEventsTableData> {
    return this.http.get(this.baseUrl + "get-extra-details/" + caseId).pipe().toPromise().then(r => r['data'] as DriveEventsTableData);
  }

  public async getOriginalTracebackFile(path: string): Promise<ArchiveFile> {
    const body = {archiveFilePath: path};
    const result = await this.http.post(this.baseUrl + "download-archive", body).pipe().toPromise();
    return {
      filename: result['file_name'],
      base64: result['base65_data']
    };
  }

}
