import {Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-show-grid-button',
  templateUrl: './show-grid-button.component.html',
  styleUrls: ['./show-grid-button.component.scss']
})
export class ShowGridButtonComponent implements OnInit {

  showGrid = false;

  @Output()
  clicked: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit() {
  }

  private handleClick() {
    this.showGrid = !this.showGrid;
    this.clicked.emit(this.showGrid);
  }

}
