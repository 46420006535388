import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import { faSearchMinus } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-reset-zoom-button',
  templateUrl: './reset-zoom-button.component.html',
  styleUrls: ['./reset-zoom-button.component.scss']
})
export class ResetZoomButtonComponent implements OnInit {

  faSearchMinus = faSearchMinus;

  @Output()
  clicked: EventEmitter<void> = new EventEmitter<void>();

  constructor() { }

  ngOnInit() {
  }

  handleClick() {
    this.clicked.emit();
  }
}
