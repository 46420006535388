import { Component, OnInit } from '@angular/core';
import { Auth } from 'aws-amplify';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  code: string;
  password: string;
  confirmPassword: string;
  errorText: string;
  showEmail = false;
  email: string;
  resettingPassword = true;

  constructor(private route: ActivatedRoute, private router: Router) {
    const navigation = this.router.getCurrentNavigation();
    if (route.snapshot.url[0].path === "confirm-email") {
      this.resettingPassword = false;
    }
    const params = route.snapshot.queryParamMap;
    const emailParam = params.get("email");
    if (emailParam) {
      this.email = emailParam;
    }
    const codeParam = params.get("code");
    if (codeParam) {
      this.code = codeParam;
    }
    if (navigation.extras && navigation.extras.state) {
      const state = navigation.extras.state as { data: string };
      this.email = state.data;
    } else {
      this.showEmail = true;
    }
  }

  ngOnInit() {
  }

  async onSubmit() {
    try {
      if (this.resettingPassword) {
        await Auth.forgotPasswordSubmit(this.email, this.code, this.password);
        await this.router.navigate(['/login'], {state: {data: "Password Successfully Reset!"}});
      } else {
        await Auth.verifyUserAttributeSubmit(await Auth.currentAuthenticatedUser(), "email", this.code);
        await this.router.navigate(['/login'], {state: {data: "Email Successfully Confirmed!"}});
      }
    } catch (error) {
      this.errorText = error.message;
    }
  }
}
