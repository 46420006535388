import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { environment } from '../../environments/environment';
import {User} from '../models/user';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private baseUrl = environment.userApiUrl;

  constructor(private http: HttpClient) { }

  public async getUser(userId): Promise<User> {
    const data = (await this.http.get(this.baseUrl + "/get-profile/" + userId).pipe().toPromise())['user'];
    const user = {
      id: data.userId,
      type: data.userType,
      email: data.emailAddress,
      company: data.companyName,
      firstName: data.firstName,
      lastName: data.lastName,
      phoneNumber: data.phoneNumber,
      enabled: data.enabled
    };
    return user;
  }

  public async getUsers(searchCriteria: Partial<User>): Promise<Array<User>> {
    const body = {
      userType: searchCriteria.type,
      companyName: searchCriteria.company,
      emailAddress: searchCriteria.email,
      phoneNumber: searchCriteria.phoneNumber
    };
    const data = await this.http.post(this.baseUrl + "/filter", body).pipe().toPromise();
    return data['data'].map(u => ({
      id: u.userId,
      type: u.userType,
      firstName: u.firstName,
      lastName: u.lastName,
      company: u.companyName,
      email: u.emailAddress,
      phoneNumber: u.phoneNumber
    }));
  }

  public async updateUser(user: User): Promise<any> {
    const params = {
      firstName: user.firstName,
      lastName: user.lastName,
      emailAddress: user.email,
      companyName: user.company,
      phoneNumber: user.phoneNumber
    };

    if (user.type) {
      params['userType'] = user.type;
    }
    return this.http.post(this.baseUrl + "/update-profile/" + user.id, params).pipe().toPromise();
  }
}
