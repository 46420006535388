import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {Ticket} from '../../../models/ticket';
import {fileToBase64} from '../../../utils/file-utils';
import {CreateTicketResponse, TicketService} from '../../../services/ticket.service';
import {AuthService} from '../../../services/auth.service';
import {states} from '../../../models/state';
import {countries} from '../../../models/country';
import { Router } from '@angular/router';

@Component({
  selector: 'app-fault-ticket',
  templateUrl: './fault-ticket.component.html',
  styleUrls: ['./fault-ticket.component.scss']
})
export class FaultTicketComponent implements OnInit {

  ticket: Partial<Ticket> = {country: "United States"};

  applicationOptions: Array<string> = ["Standard", "Sync Transfer", "Vector Control", "Master & Follower",
    "Synchronous / PM Motor", "Regen", "Pump", "Fan", "Compressor", "Other"];

  systemOptions: Array<string> = ["Outside air HVAC cooling", "Closed loop HVAC cooling",  "Outdoor VFD",
    "Outdoor Converter/Indoor Inverter", "Master / follower",  "AC Common Bus VFD",
    "Non TIC Toshiba Bypass / Output Switchgear & Control Gear",  "Other"];

  stateOptions: Array<string> = states;
  countryOptions: Array<string> = countries;

  fileToUpload: File = undefined;

  errorText: string;
  successText: string;

  isTechnician: boolean;

  @ViewChild('fileInput', {static: false})
  fileInputRef: ElementRef;
  filePlaceholder: any;

  private buttonDisabled = false;

  constructor(private authService: AuthService, private ticketService: TicketService, private router: Router) { }

  ngOnInit() {
    this.init().then();
  }

  private async init() {
    const currentUser = await this.authService.getCurrentUser();
    this.ticket.customerUserId = currentUser.id;
    this.isTechnician = currentUser.type === "technician";
    if (!this.isTechnician) {
      this.ticket.companyName = currentUser.company;
      this.ticket.customerEmailAddress = currentUser.email;
    }
  }

  async onSubmit(myForm: NgForm) {
    this.buttonDisabled = true;
    if (myForm.valid) {
      this.errorText = undefined;
      this.successText = undefined;
      try {
        this.ticket.tracebackFileBase64 = await fileToBase64(this.fileToUpload);
        // remove the following content because it was failing during backend parsing
        if (this.ticket.tracebackFileBase64.split(',').length > 1) {
          this.ticket.tracebackFileBase64 = this.ticket.tracebackFileBase64.split(",")[1];
        }
        const splitFilename = this.fileToUpload.name.split(".");
        this.ticket.tracebackFileType = splitFilename[splitFilename.length - 1];
        this.ticket.tracebackFileName = splitFilename[0];
        this.ticket.status = "In progress";
        const ticketResponse: CreateTicketResponse = await this.ticketService.createTicket(this.ticket);
        this.successText = `Ticket #${ticketResponse.ticket_number} successfully created`;
        myForm.resetForm();
        await this.init();
        this.fileInputRef.nativeElement.value = "";
      } catch (error) {
        this.errorText = error.message;
      }
    }
    this.buttonDisabled = false;
  }

  handleFileInput(event: Event) {
    this.fileToUpload = event.target['files'][0] as File;
  }

  closePage() {
    if (this.isTechnician) {
      this.router.navigate(['/tickets']);
    } else {
      this.router.navigate(['/home']);
    }
  }
}
