import {Component, Input, OnInit} from '@angular/core';
import {Ticket} from '../../../models/ticket';

export interface FaultCodes {
  primaryFaultCode: string;
  secondaryFaultCodes: Array<string>;
}

@Component({
  selector: 'app-fault-codes-list',
  templateUrl: './fault-codes-list.component.html',
  styleUrls: ['./fault-codes-list.component.scss']
})
export class FaultCodesListComponent implements OnInit {

  @Input()
  faultCodes: FaultCodes;

  constructor() { }

  ngOnInit() {
  }

}
