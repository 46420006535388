import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RecommendationService {

  private baseUrl = environment.ruleEngineUrl;
  private configurationUrl = environment.configurationUrl;

  constructor(private http: HttpClient) { }

  public async getRecommendations(primaryFaultCode: string, secondaryFaultCodes?: Array<string>): Promise<Array<string>> {
    const body = {
      name: "Fault Recommendations",
      primaryFaultCode: primaryFaultCode
    };

    if (secondaryFaultCodes) {
      body['secondaryFaultCode'] = secondaryFaultCodes.join(",");
    }

    const response = await this.http.post(this.baseUrl, body).pipe().toPromise();
    return response['recommendations'];
  }

  public async resetRulesEngine(): Promise<any> {
    return this.http.post(this.configurationUrl + "/reset-rule-engine", {}).pipe().toPromise();
  }
}
