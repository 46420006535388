import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './components/app.component';
import { LoginComponent } from './components/user/login/login.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {FormsModule} from '@angular/forms';
import { AmplifyAngularModule, AmplifyService } from 'aws-amplify-angular';
import { ForgotPasswordComponent } from './components/user/forgot-password/forgot-password.component';
import {RouterModule} from '@angular/router';
import { RegisterComponent } from './components/user/register/register.component';
import { ErrorOrSuccessTextComponent } from './components/utilities/error-text/error-or-success-text.component';
import { ResetPasswordComponent } from './components/user/reset-password/reset-password.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {TokenInterceptor} from './services/auth.interceptor';
import { NavbarComponent } from './components/navbar/navbar.component';
import { ListFaultsComponent } from './components/ticket/list-faults/list-faults.component';
import { FaultTicketComponent } from './components/ticket/fault-ticket/fault-ticket.component';
import { GraphComponent } from './components/dashboard/graph/graph.component';
import {ChartsModule} from 'ng2-charts';
import { GraphPageComponent } from './components/dashboard/graph-page/graph-page.component';
import { DashboardComponent } from './components/dashboard/dashboard/dashboard.component';
import { SignalSelectorComponent } from './components/dashboard/signal-selector/signal-selector.component';
import { SignalDataComponent } from './components/dashboard/signal-data/signal-data.component';
import { GraphFooterComponent } from './components/dashboard/graph-footer/graph-footer.component';
import { ScaleSelectorComponent } from './components/dashboard/scale-selector/scale-selector.component';
import { ScreenshotListComponent } from './components/dashboard/screenshot-list/screenshot-list.component';
import {AngularFontAwesomeModule} from 'angular-font-awesome';
import { RecommendationPageComponent } from './components/recommendation-page/recommendation-page.component';
import { SameGraphButtonComponent } from './components/buttons/same-graph-button/same-graph-button.component';
import { SplitColumnsButtonComponent } from './components/buttons/split-columns-button/split-columns-button.component';
import { RecommendationSidebarComponent } from './components/dashboard/recommendation-sidebar/recommendation-sidebar.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { PageSelectorComponent } from './components/dashboard/page-selector/page-selector.component';
import { PredefinedViewsComponent } from './components/dashboard/predefined-views/predefined-views.component';
import { RecommendationEmailTemplateComponent } from './components/recommendation-email-template/recommendation-email-template.component';
import { SearchListProfileComponent } from './components/user/search-list-profile/search-list-profile.component';
import { ShowPointsButtonComponent } from './components/buttons/show-points-button/show-points-button.component';
import { ShowGridButtonComponent } from './components/buttons/show-grid-button/show-grid-button.component';
import { ShowTimeButtonComponent } from './components/buttons/show-time-button/show-time-button.component';
import { ZoomButtonComponent } from './components/buttons/zoom-button/zoom-button.component';
import { HomeComponent } from './components/home/home.component';
import { DriveEventsTableComponent } from './components/dashboard/drive-events-table/drive-events-table.component';
import { ResetZoomButtonComponent } from './components/buttons/reset-zoom-button/reset-zoom-button.component';
import { FaultCodesListComponent } from './components/dashboard/fault-codes-list/fault-codes-list.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ForgotPasswordComponent,
    RegisterComponent,
    ErrorOrSuccessTextComponent,
    ResetPasswordComponent,
    NavbarComponent,
    FaultTicketComponent,
    ListFaultsComponent,
    GraphComponent,
    GraphPageComponent,
    DashboardComponent,
    SignalSelectorComponent,
    SignalDataComponent,
    GraphFooterComponent,
    ScaleSelectorComponent,
    SameGraphButtonComponent,
    ScreenshotListComponent,
    RecommendationPageComponent,
    SplitColumnsButtonComponent,
    RecommendationSidebarComponent,
    PageSelectorComponent,
    PredefinedViewsComponent,
    RecommendationEmailTemplateComponent,
    SearchListProfileComponent,
    ShowPointsButtonComponent,
    ShowGridButtonComponent,
    ShowTimeButtonComponent,
    ZoomButtonComponent,
    HomeComponent,
    DriveEventsTableComponent,
    ResetZoomButtonComponent,
    FaultCodesListComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    FormsModule,
    AmplifyAngularModule,
    RouterModule,
    HttpClientModule,
    ChartsModule,
    AngularFontAwesomeModule,
    FontAwesomeModule
  ],
  providers: [AmplifyService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    }],
  bootstrap: [AppComponent]
})
export class AppModule { }
