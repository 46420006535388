import {Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-same-graph-button',
  templateUrl: './same-graph-button.component.html',
  styleUrls: ['./same-graph-button.component.scss']
})
export class SameGraphButtonComponent implements OnInit {

  @Output()
  clicked: EventEmitter<boolean> = new EventEmitter<boolean>();

  sameGraph = false;

  constructor() { }

  ngOnInit() {
  }

  handleClick() {
    this.sameGraph = !this.sameGraph;
    this.clicked.emit(this.sameGraph);
  }
}
