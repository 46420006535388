import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {FaultData, Signal} from '../../../models/graph-data';
import {GraphControlService, NewSignalEvent} from '../../../services/graph-control.service';
import {Subscription} from 'rxjs';
import {SignalSelection} from '../signal-selector/signal-selector.component';
import {roundToDecimalPlaces, scale} from '../../../utils/math-utils';
import {ScaleConfig} from '../scale-selector/scale-selector.component';
import * as cloneDeep from 'clone-deep';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FaultFile, Ticket} from '../../../models/ticket';
import {Router} from '@angular/router';
import {colorOptions} from '../../../models/color';
import {AnnotationService} from '../../../services/annotation/annotation.service';
import {ScaleService} from '../../../services/scale.service';

export interface GraphState {
  graphNumber: number;
  signals: Array<SignalConfig>;
}

export interface SignalConfig {
  xMin: number;
  xMax: number;
  xDistance: number;
  multiplier: number;
  signal: Signal;
  color: string;
  lineThickness: number;
}

export interface DataTableEntry {
  signalCode: string;
  p1Value: number;
  p2Value: number;
  pDiffValue: number;
  min: number;
  max: number;
  average: string;
}

@Component({
  selector: 'app-graph-page',
  templateUrl: './graph-page.component.html',
  styleUrls: ['./graph-page.component.scss']
})
export class GraphPageComponent implements OnInit, AfterViewInit, OnDestroy {

  graphHeight: string;

  signalSelectedSubscription: Subscription;
  private initialCreated = true;

  @Input('signalData')
  set setSignalData(data: FaultData) {
    this.signalData = data;
    this.initialCreated = true;
    this.maxPageNumber = 50;
    this.initiateGraphs();
    this.generatePages();
    this.setSignalDataTableValues();
    this.updateYScaleConfigs();
    this.resetXMinMax();
  }

  @Input('signalData2')
  set setSignalData2(data: FaultData) {
    this.signalData2 = data;
  }

  comparingFiles: boolean;

  showPoints = false;
  showGrid = false;
  showTime = false;

  signalData: FaultData;
  signalData2: FaultData;

  pageNumber = 1;
  maxPageNumber: number;

  @Input('sameGraph')
  set setSameGraph(state: boolean) {
    if (state) {
      this.previousGraphStates = cloneDeep(this.graphs);
      this.graphs = [{graphNumber: 1, signals: [].concat.apply([], this.graphs.map(g => g.signals))}];
      this.setSignalDataTableValues();
      this.updateYScaleConfigs();
      this.graphControlService.announceSameGraphEvent(state);
    } else if (this.previousGraphStates) {
      this.graphs = cloneDeep(this.previousGraphStates);
      this.setSignalDataTableValues();
      this.updateYScaleConfigs();
      this.graphControlService.announceSameGraphEvent(state);
    }
    this.setColumns();
  }

  @Input('showPoints')
  set setShowPoints(state: boolean) {
    this.showPoints = state;
  }

  @Input('showGrid')
  set setShowGrid(state: boolean) {
    this.showGrid = state;
  }

  @Input('showTime')
  set setShowTime(state: boolean) {
    this.showTime = state;
  }

  @Input('multiColumn')
  set setMultiColumn(state: boolean) {
    this.numColumns = state ? 2 : 1;
    this.setColumns();
  }

  @Input('comparingFiles')
  set setComparingFiles(state: boolean) {
    this.comparingFiles = state;
  }

  @Input()
  comparingExternalFiles: boolean;

  @Input('comparingTicket')
  set setComparingTicket(ticket: Ticket) {
    this.comparingTicket = ticket;
    if (ticket) {
      this.showTicketFiles().then();
    }
  }

  comparingTicket: Ticket;

  @Input('faults')
  set setFaults(faults: Array<FaultFile>) {
    this.faults = faults;
    this.compareOptions = cloneDeep(this.faults);
    this.compareOptions.push({fileName: this.compareOtherFileOption, fullPath: ""});
  }

  faults: Array<FaultFile>;
  compareOtherFileOption = "--Search Other Traceback Files--";
  compareOptions: Array<FaultFile>;

  @Input()
  ticket: Ticket;

  @Input()
  currentFileName: string;

  @Input()
  currentFileName2: string;

  @Output()
  fileNameSelected: EventEmitter<string> = new EventEmitter<string>();

  numColumns = 1;

  graphs: Array<GraphState>;

  columns: Array<Array<GraphState>>;

  previousGraphStates: Array<GraphState>;

  dataTableEntries: Array<DataTableEntry>;

  firstFilenameSelected = this.currentFileName;
  secondFilenameSelected: string | undefined;

  @ViewChild('chartSection', {static: false})
  headerElement: ElementRef;

  @ViewChild('filemodal', {static: false})
  fileModal;

  annotationPositions = [];
  xMin = 0;
  xMax = 0;

  yScaleConfigs: Array<ScaleConfig> = [];

  columnClass = 'col-12 column-row';

  private currentSignalSelections: Array<Partial<SignalSelection>>;
  private pageStates: Array<{pageNumber: number, signals: Array<Partial<SignalSelection>>}> = [];

  constructor(private ref: ElementRef,
              private router: Router,
              private graphControlService: GraphControlService,
              private readonly modalService: NgbModal,
              private readonly annotationService: AnnotationService,
              private readonly scaleService: ScaleService) {

    this.signalSelectedSubscription = this.graphControlService.signalSelected$.subscribe(event => {
      if (event.selections.length) {
        this.currentSignalSelections = event.selections;
        this.handleSignalSelected(event.selections, event.resetXMinMax);
      }
    });

    this.scaleService.rawValues$.subscribe((event: Array<number>) => {
      this.handleXScaleUpdated(event);
    });

    this.graphControlService.yMinMaxUpdated$.subscribe(event => {
      this.yScaleConfigs = [].concat(event.configs);
      this.setColumns();
    });

    this.graphControlService.mathSignalCreated$.subscribe((event: NewSignalEvent) => {
      const graph = this.graphs.find(g => g.graphNumber === event.graphNumber);
      const signal = graph.signals.find(s => s.signal.signalCode === event.oldSignalName);
      if (signal) {
        signal.signal = event.newSignal;
      }
    });

    this.annotationService.rawPositions$.subscribe(positions => {
      this.annotationPositions = positions;
      this.setSignalDataTableValues();
    });

  }

  setZoom() {
    const min = this.annotationPositions[0];
    const max = this.annotationPositions[1];
    this.scaleService.setRawValues(this.annotationPositions);
    const amountToShift = Math.abs((max - min) * 0.05);
    this.annotationService.setRawPositions([min + amountToShift, max - amountToShift]);
  }

  resetZoom() {
    this.resetXMinMax();
  }

  private setColumns() {
    this.columns = [];
    if (this.numColumns === 1) {
      this.columns.push(this.graphs);
    } else {
      const numPerColumn = Math.ceil(this.graphs.length / 2);
      this.columns.push(this.graphs.slice(0, numPerColumn));
      this.columns.push(this.graphs.slice(numPerColumn));
    }
    this.graphHeight = (100 / (Math.ceil(this.graphs.length / this.numColumns))) + "%";
    this.columnClass = this.numColumns === 1 ? 'col-12 column-row' : 'col-6 column-row';
  }

  private handleXScaleUpdated(event: Array<number>) {
    this.xMin = event[0];
    this.xMax = event[1];
    if (this.annotationPositions[0] < this.xMin) {
      this.annotationPositions[0] = this.xMin;
    }
    if (this.annotationPositions[1] > this.xMax) {
      this.annotationPositions[1] = this.xMax;
    }
    this.annotationPositions = [...this.annotationPositions];
    this.annotationService.setRawPositions(this.annotationPositions);
    this.setSignalDataTableValues();
  }

  private handleSignalSelected(event: Array<Partial<SignalSelection>>, resetXMinMax: boolean) {
    this.graphs = [];
    for (let i = 0; i < 8; i++) {
      const signals = event.filter(selection => {
        return selection.graphNumber - 1 === i;
      });
      let xMin = 0;
      let xDistance = 0;
      let xMax = 0;

      if (signals.length) {
        const signalConfigs = signals.filter(s => s.signalCode).map(signal => {
          const signalData = this.signalData.signals.find(s => s.signalCode === signal.signalCode);
          if (signalData !== undefined) {
            xMin = ((signalData.faultIdx - signalData.blockMax) / 1000000 * signalData.samplingRate);
            xDistance = 1 / 1000000 * signalData.samplingRate;
            xMax = xMin + (xDistance * signalData.signalData.length);
          }

          const multiplier = this.getPageMultiplier(xMin, xMax);

          return {
            xMin: xMin,
            xMax: xMax,
            multiplier: multiplier,
            xDistance: xDistance,
            signal: signalData as Signal,
            color: this.comparingFiles ? '#D0031B' : signal.color,
            lineThickness: signal.lineThickness
          };
        });
        if (this.comparingFiles) {
          const signalConfigs2 = signals.filter(s => s.signalCode).map(signal => {
            const signalData = this.signalData2.signals.find(s => s.signalCode === signal.signalCode);
            if (!signalData) {
              return;
            }
            xMin = ((signalData.faultIdx - signalData.blockMax) / 1000000 * signalData.samplingRate);
            xDistance = 1 / 1000000 * signalData.samplingRate;
            xMax = xMin + (xDistance * signalData.signalData.length);

            const multiplier = this.getPageMultiplier(xMin, xMax);

            return {
              xMin: xMin,
              xMax: xMax,
              multiplier: multiplier,
              xDistance: xDistance,
              signal: signalData as Signal,
              color: '#0905F9',
              lineThickness: signal.lineThickness
            };
          });
          signalConfigs.push(...signalConfigs2.filter(s => s && s.signal && s.multiplier));
        }
        this.graphs.push({graphNumber: i + 1, signals: signalConfigs});
      }
    }
    // Find smallest multipler
    let smallestMultiplier = this.graphs[0].signals[0].multiplier;
    for (const graph of this.graphs) {
      for (const signal of graph.signals) {
        smallestMultiplier = signal.multiplier < smallestMultiplier ? signal.multiplier : smallestMultiplier;
      }
    }
    this.annotationService.setMultiplier(smallestMultiplier);
    this.scaleService.setMultiplier(smallestMultiplier);
    if (this.initialCreated) {
      this.initialCreated = false;
      this.resetXMinMax();
      this.setPageNumber(1);
    }
    if (resetXMinMax) {
      this.resetXMinMax();
    }
    this.setColumns();
    this.annotationPositions = [...this.annotationPositions];
    this.setSignalDataTableValues();
    this.updateYScaleConfigs();
  }

  private getPageMultiplier(xMin: number, xMax: number) {
    const largest = Math.max(Math.abs(xMin), Math.abs(xMax));
    if (largest >= 10) {
      return 1;
    } else if (largest >= 0.01) {
      return 1000;
    } else {
      return 1000000;
    }
  }

  private setSignalDataTableValues() {
    this.dataTableEntries = [];
    for (const graph of this.graphs || []) {
      for (const signal of graph.signals) {
        const signalData = this.signalData.signals.find(s => s.signalCode === signal.signal.signalCode);
        if (!this.dataTableEntries.find(d => d.signalCode === signal.signal.signalCode)) {
          const p1Value = signalData.signalData[
            Math.round(scale(this.annotationPositions[0], signal.xMin, signal.xMax, 0, signalData.signalData.length))
            ];
          const p2Value = signalData.signalData[
            Math.round(scale(this.annotationPositions[1], signal.xMin, signal.xMax, 0, signalData.signalData.length))
            ];
          const dataBetweenAnnotations = signalData.signalData.map((y: number, i) => ({x: signal.xMin + (i * signal.xDistance), y: y}))
            .filter((data, i) => {
            return data.x >= this.annotationPositions[0] && data.x <= this.annotationPositions[1];
          });
          this.dataTableEntries.push({
            signalCode: signalData.signalDisplayName || signalData.signalCode,
            p1Value: p1Value,
            p2Value: p2Value,
            pDiffValue: p2Value - p1Value,
            min: Math.min(...signalData.signalData),
            max: Math.max(...signalData.signalData),
            average: (dataBetweenAnnotations.reduce((acc, c) => acc + c.y, 0) / dataBetweenAnnotations.length).toFixed(3)
          });
        }
      }
    }
    this.graphControlService.announceSignalDataUpdated(this.dataTableEntries);
  }

  ngOnInit() {
    this.setColumns();
    this.setSignalDataTableValues();
    this.updateYScaleConfigs();
  }

  private initiateGraphs() {
    this.graphs = [];
    this.setColumns();
  }

  async fileOpenClicked() {
    try {
      const result = await (this.modalService.open(this.fileModal, {ariaLabelledBy: 'filemodal', size: 'lg'}).result);
      if (this.firstFilenameSelected && !this.secondFilenameSelected) {
        this.fileNameSelected.next(this.firstFilenameSelected);
      } else if (this.secondFilenameSelected === this.compareOtherFileOption) {
        await this.router.navigate(['/tickets'], {state: {data: {parentTicket: this.ticket, firstFile: this.firstFilenameSelected}}});
      } else if (this.comparingExternalFiles) {
        this.graphControlService
          .announceCompareExternalFiles(this.ticket, this.firstFilenameSelected, this.comparingTicket, this.secondFilenameSelected);
      } else {
        this.graphControlService.announceCompareLocalFiles(this.firstFilenameSelected, this.secondFilenameSelected);
      }
    } catch (e) {

    }
  }

  private updateYScaleConfigs() {
    const existingScales = [...this.yScaleConfigs];
    this.yScaleConfigs = this.graphs.map(g => {
      const existingScale = existingScales.find(s => s.graphNumber === g.graphNumber);
      let yMin = Math.min(...g.signals.map(s => Math.min(...s.signal.signalData)));
      let yMax = Math.max(...g.signals.map(s => Math.max(...s.signal.signalData)));
      // In case where all data is the same value, we need to add space in the yMin and yMax, otherwise chartjs doesn't display line
      if (yMin === yMax) {
        yMin -= 10;
        yMax += 10;
      }
      return {
        graphNumber: g.graphNumber,
        yMin: yMin,
        yMax: yMax,
        autoScale: existingScale ? existingScale.autoScale : false
      };
    });
    this.graphControlService.announceYMinMaxUpdated(this.yScaleConfigs, false);
  }

  ngAfterViewInit(): void {

  }

  ngOnDestroy(): void {
    this.signalSelectedSubscription.unsubscribe();
  }

  private async showTicketFiles() {
    setTimeout(() => {
      this.modalService.open(this.fileModal, {ariaLabelledBy: 'filemodal', size: 'lg'}).result.then(() => {
        this.graphControlService
          .announceCompareExternalFiles(this.ticket, this.firstFilenameSelected, this.comparingTicket, this.secondFilenameSelected);
      });
    });

  }

  private resetXMinMax() {
    let totalXMin = Infinity;
    let totalXMax = -Infinity;
    for (const graph of this.graphs) {
      const min = Math.min(...graph.signals.map(s => s.xMin));
      const max = Math.max(...graph.signals.map(s => s.xMax));
      totalXMin = Math.min(totalXMin, min);
      totalXMax = Math.max(totalXMax, max);
    }
    this.scaleService.setRawValues([roundToDecimalPlaces(totalXMin, 2), roundToDecimalPlaces(totalXMax, 2)]);
    this.annotationService.setRawPositions([totalXMin + .005, totalXMax - .005]);
  }

  setPageNumber(event: number) {
    const currentPage = this.pageStates.find(p => p.pageNumber === this.pageNumber);
    if (this.currentSignalSelections) {
      currentPage.signals = this.currentSignalSelections;
    }

    this.pageNumber = event;
    const existingState = this.pageStates.find(p => p.pageNumber === this.pageNumber);
    this.graphControlService.announceSignalSelected(existingState.signals, true);
  }

  private generatePages() {
    const pageStates = [];
    let pageNumber = 1;
    let previousSignal: Signal;
    let signalSelections: Array<Partial<SignalSelection>> = [];
    let count = 0;
    let id = 0;
    for (const signal of this.signalData.signals) {
      if (signalSelections.length && previousSignal &&
        (previousSignal.samplingRate !== signal.samplingRate || previousSignal.faultIdx !== signal.faultIdx)) {
        this.fillEmptySignalSelections(signalSelections, id);
        pageStates.push({pageNumber: pageNumber, signals: cloneDeep(signalSelections)});
        signalSelections = [];
        pageNumber++;
        id = 0;
        count = 0;
      }
      signalSelections.push({id: id++, signalCode: signal.signalCode, graphNumber: count + 1,
        color: colorOptions[id % colorOptions.length], lineThickness: 2 });
      count++;
      if (count >= 4) {
        this.fillEmptySignalSelections(signalSelections, id);
        pageStates.push({pageNumber: pageNumber, signals: cloneDeep(signalSelections)});
        signalSelections = [];
        pageNumber++;
        id = 0;
        count = 0;
      }

      previousSignal = signal;
    }
    if (signalSelections.length) {
      this.fillEmptySignalSelections(signalSelections, id);
      pageStates.push({pageNumber: pageNumber, signals: cloneDeep(signalSelections)});
    }
    this.pageStates = pageStates;
    this.maxPageNumber = this.pageStates.length;
    this.setPageNumber(1);
  }

  private fillEmptySignalSelections(signals: Array<Partial<SignalSelection>>, id: number) {
    for (let i = id; i < 12; i++) {
      signals.push({id: id++, color: colorOptions[id % colorOptions.length], lineThickness: 2});
    }
  }

}
