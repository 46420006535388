import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import { faSearchPlus } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-zoom-button',
  templateUrl: './zoom-button.component.html',
  styleUrls: ['./zoom-button.component.scss']
})
export class ZoomButtonComponent implements OnInit {

  faSearchPlus = faSearchPlus;

  @Output()
  clicked: EventEmitter<void> = new EventEmitter<void>();

  constructor() { }

  ngOnInit() {
  }

  handleClick() {
    this.clicked.emit();
  }
}
