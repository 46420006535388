import {Component, Input, OnInit} from '@angular/core';
import {Dataset, DriveEventsTableData, EventCount, TroubleRecord} from '../../../models/drive-events';

@Component({
  selector: 'app-drive-events-table',
  templateUrl: './drive-events-table.component.html',
  styleUrls: ['./drive-events-table.component.scss']
})
export class DriveEventsTableComponent implements OnInit {

  title: string;
  tableHeaders: Array<string> = [];

  private headerOptions = {
    "Dataset": ["ID", "Symbol", "Data", "Unit"],
    "Event Count": ["ID", "Title", "Event Data", "Unit", "TimeS", "Event"],
    "Trouble Record": ["ID", "TimeS", "Fi1", "Fi2", "Fi1_Data", "Fi2_Data"]
  };

    private sortSettings = {
    "Dataset": {
      column: '1:visible',
      order: 'asc'
    },
    "Event Count": {
      column: '0:visible',
      order: 'asc'
    },
    "Trouble Record": {
      column: '1:visible',
      order: 'desc'
    }
  }

  private currentTableData: Array<Array<string>> = [];

  table: DataTables.Api;

  @Input('dataType')
  set setDataType(type: string) {
    this.title = type;
    this.tableHeaders = this.headerOptions[type];
    this.reDrawTable();
  }

  @Input('tableData')
  set setTableData(data: DriveEventsTableData) {
    this.tableData = data;
    this.reDrawTable();
  }
  tableData: DriveEventsTableData;

  constructor() { }

  ngOnInit() {
  }

  private reDrawTable() {
    if (this.title && this.tableData) {
      const currentTableData = [];
      if (this.title === 'Dataset') {
        this.tableData.DataSet.forEach(data =>
        currentTableData.push([data.id.toString(), data.symbol, data.data, data.unit]));
      } else if (this.title === 'Event Count') {
        this.tableData.EventCount.forEach(data =>
        currentTableData.push([data.id.toString(), data.titleUS, data.eventData, data.unit, data.timeS, data.event.toString()]));
      } else {
        this.tableData.TroubleRecord.forEach(data =>
        currentTableData.push(
          [data.id.toString(), data.timeS, data.fi1.toString(), data.fi2.toString(), data.fi1_Data, data.fi2_Data]));
      }

      this.currentTableData = currentTableData;
      if (this.table) {
        this.table.destroy();
      }
      const settings = this.sortSettings[this.title];
      $(document).ready(() => {
        this.table = $('#dataTable').DataTable({
          paging: true,
          pageLength: 10,
          lengthMenu: [10, 25, 50, 75, 100],
          lengthChange: true,
          autoWidth: true
        });
        this.table.column(settings.column).order(settings.order).draw();
      });
    }
  }

}
