import {Component, Input, ViewChild} from '@angular/core';
import {GraphControlService} from '../../../services/graph-control.service';
import {FaultData, Signal} from '../../../models/graph-data';
import * as cloneDeep from 'clone-deep';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {mathOperator} from '../../../utils/math-utils';
import {colorOptions} from '../../../models/color';
import {PredefinedView} from '../../../services/fault.service';

export interface SignalSelection {
  id: number;
  signalCode: string;
  graphNumber: number | undefined;
  color: string;
  lineThickness: number;
}

export interface MathSignalConfig {
  name: string;
  multiplier1: number;
  signal1: string;
  operator: '+'|'-'|'÷'|'x';
  multiplier2: number;
  signal2: string;
}

@Component({
  selector: 'app-signal-selector',
  templateUrl: './signal-selector.component.html',
  styleUrls: ['./signal-selector.component.scss']
})
export class SignalSelectorComponent {

  signals: Array<Signal>;
  private mathErrorText: string;

  @Input('defaultSignals')
  set setDefaultSignals(signals: Array<string>) {
    this.defaultSignals = signals;
    // this.init();
  }

  @Input()
  signalData: FaultData;

  defaultSignals: Array<string>;

  @Input()
  comparingFiles: boolean;

  colorOptions = colorOptions;

  signalNames: Array<{signalCode: string, signalDisplayName: string}>;
  mathSignalNameOptions: Array<{signalCode: string, signalDisplayName: string}>;

  signalSelectors: Array<Partial<SignalSelection>> = [];
  oldSignalSelections: Array<Partial<SignalSelection>> = [];

  previousSelectorState: Array<Partial<SignalSelection>>;

  numGraphs = 4;

  graphOptions = [1, 2, 3, 4];

  sameGraph = false;

  mathOption = {signalCode: "Add new math signal...", signalDisplayName: "Add new math signal..."};

  @ViewChild('mathmodal', {static: false})
  mathModal;

  mathSignalConfig: Partial<MathSignalConfig> = {};

  constructor(private readonly graphControlService: GraphControlService, private readonly modalService: NgbModal) {
    this.graphControlService.sameGraphEvent$.subscribe(event => {
      this.handleSameGraph(event);
    });
    this.graphControlService.signalsLoaded$.subscribe(signals => {
      this.signalNames = signals.map(s => ({signalCode: s.signalCode, signalDisplayName: s.signalDisplayName || s.signalCode}));
      this.mathSignalNameOptions = [...this.signalNames];
      this.signalNames.push(this.mathOption);
      this.signalNames.push({signalCode: "", signalDisplayName: ""});
      this.signals = signals;
    });
    this.graphControlService.signalSelected$.subscribe(event => {
      if (event.selections.length) {
        this.numGraphs = Math.max(0, ...event.selections.map(s => s.graphNumber).filter(s => s));
        this.setGraphOptions();
        if (this.signalSelectors && this.signalSelectors.length) {
          this.oldSignalSelections = cloneDeep(this.signalSelectors);
        }
        this.signalSelectors = cloneDeep(event.selections);
      }
    });
    this.graphControlService.predefinedViewSelected$.subscribe(view => this.handlePredefinedView(view));
  }

  async showMathModal(): Promise<Signal | undefined> {
    try {
      await this.modalService.open(this.mathModal, {ariaLabelledBy: 'mathmodal', size: 'xl'}).result;
      const newSignal = this.createMathSignal();
      this.mathSignalConfig = {};
      return newSignal;
    } catch (e) {
      this.mathSignalConfig = {};
      return undefined;
    }
  }

  async onSignalSelected(selector: Partial<SignalSelection>) {
    const oldSignalName = this.oldSignalSelections.find(s => s.id === selector.id).signalCode;
    if (selector.signalCode === this.mathOption.signalCode) {
      const newSignal = await this.showMathModal();
      if (newSignal) {
        selector.signalCode = newSignal.signalCode;
        this.graphControlService.announceMathSignalCreated(selector.graphNumber, oldSignalName, newSignal);
      } else {
        selector.signalCode = oldSignalName;
        this.signalSelectors = [...this.signalSelectors];
        return;
      }
    }
    // Selecting a new signal, add a new graph
    if (!selector.graphNumber) {
      if (this.numGraphs < 8) {
        this.numGraphs++;
      }
      selector.graphNumber = this.numGraphs;
    }
    if (!selector.signalCode || !selector.signalCode.length) {
      selector.graphNumber = undefined;
      let runningGraphNumber = 1;
      for (const sel of [...this.signalSelectors].filter(s => s.graphNumber).sort((a, b) => a.graphNumber - b.graphNumber)) {
        if (sel.graphNumber > runningGraphNumber) {
          sel.graphNumber = runningGraphNumber;
        }
        runningGraphNumber++;
      }
    }
    this.numGraphs = this.signalSelectors.filter(s => s.graphNumber).map(s => s.graphNumber)
      .filter((v, i, a) => a.indexOf(v) === i).length;
    this.graphControlService.announceSignalSelected(this.signalSelectors);
    this.setGraphOptions();
    this.oldSignalSelections = cloneDeep(this.signalSelectors);
  }

  private setGraphOptions() {
    this.graphOptions = Array(this.numGraphs + 1).fill(0).map((x, i) => i);
  }

  colorSelected(selector: Partial<SignalSelection>, color: string) {
    selector.color = color;
    this.onSignalSelected(selector).then();
  }

  private handleSameGraph(event: boolean) {
    this.sameGraph = event;
    if (event) {
      this.previousSelectorState = cloneDeep(this.signalSelectors);
      this.signalSelectors.filter(s => s.graphNumber).forEach(s => s.graphNumber = 1);
    } else {
      this.signalSelectors = cloneDeep(this.previousSelectorState);
    }
  }

  private createMathSignal(viewName?: string,
                           signal1Name?: string,
                           signal2Name?: string,
                           operator?: string,
                           multiplier1?: number,
                           multiplier2?: number) {

    const signal1 = this.signals.find(s => s.signalCode === (signal1Name || this.mathSignalConfig.signal1));
    const signal2 = this.signals.find(s => s.signalCode === (signal2Name || this.mathSignalConfig.signal2));

    multiplier1 = multiplier1 || this.mathSignalConfig.multiplier1;
    multiplier2 = multiplier2 || this.mathSignalConfig.multiplier2;

    const newSignalData = [];
    for (let i = 0; i < signal1.signalData.length; i++) {
      newSignalData.push(
        mathOperator[operator || this.mathSignalConfig.operator](multiplier1 * signal1.signalData[i], multiplier2 * signal2.signalData[i]));
    }

    const newSignal: Signal = {
      ...signal1,
      signalCode: viewName || this.mathSignalConfig.name,
      signalName: viewName ||  this.mathSignalConfig.name,
      signalDisplayName: viewName || this.mathSignalConfig.name,
      signalData: newSignalData,
      signalMin: Math.min(...newSignalData),
      signalMax: Math.max(...newSignalData)
    };

    this.signalNames.splice(this.signalNames.length - 2, 0,
      {signalCode: newSignal.signalCode, signalDisplayName: newSignal.signalDisplayName});
    this.signals.splice(this.signals.length - 2, 0, newSignal);

    return newSignal;
  }

  private handlePredefinedView(view: PredefinedView) {
    this.previousSelectorState = cloneDeep(this.signalSelectors);
    const newSelectors: Array<Partial<SignalSelection>> = [];
    let id = 0;
    for (const signal of view.signals) {
      for (const signalCode of signal.signals) {
        if (typeof (signalCode) === 'object') {
          const signalName = Object.keys(signalCode)[0];
          const mathSignal = this.createMathSignal(signalName,
            signalCode[signalName]['signal1'],
            signalCode[signalName]['signal2'],
            signalCode[signalName]['operator'],
            signalCode[signalName]['multiplier1'],
            signalCode[signalName]['multiplier2']);
          newSelectors.push({
            id: id,
            signalCode: mathSignal.signalCode,
            graphNumber: signal.channel,
            color: colorOptions[id % colorOptions.length],
            lineThickness: 2
          });
        } else {
          newSelectors.push({
            id: id,
            signalCode: signalCode,
            graphNumber: signal.channel,
            color: colorOptions[id % colorOptions.length],
            lineThickness: 2
          });
        }
        id++;
      }
    }
    const numToAdd = 12 - newSelectors.length;
    for (let i = 0; i < numToAdd; i++) {
      newSelectors.push({id: id, color: colorOptions[id % colorOptions.length], lineThickness: 2});
      id++;
    }
    this.oldSignalSelections = cloneDeep(this.signalSelectors);
    this.signalSelectors = newSelectors;
    this.numGraphs = this.signalSelectors.filter(s => s.graphNumber).map(s => s.graphNumber)
      .filter((v, i, a) => a.indexOf(v) === i).length;
    this.graphControlService.announceSignalSelected(this.signalSelectors, true);
  }

  validateMathSignal() {
    const signal1 = this.signalData.signals.find(s => s.signalCode === this.mathSignalConfig.signal1);
    const signal2 = this.signalData.signals.find(s => s.signalCode === this.mathSignalConfig.signal2);
    if (!signal1 || !signal2) {
      this.mathErrorText = "Must select both signals";
      return false;
    }
    if (signal1.faultIdx !== signal2.faultIdx || signal1.samplingRate !== signal2.samplingRate) {
      this.mathErrorText = "Signals must have same sample rate";
      return false;
    }
    return true;
  }
}
